import firebaseConfig from 'firebase'

const config = {
    apiKey: "AIzaSyCNIoD2P5RwcPfdENsGKjOBokIFoqsuvt0",
    authDomain: "personalapp-b2c69.firebaseapp.com",
    databaseURL: "https://personalapp-b2c69.firebaseio.com",
    projectId: "personalapp-b2c69",
    storageBucket: "personalapp-b2c69.appspot.com",
    messagingSenderId: "144332257251"
};
firebaseConfig.initializeApp(config);

export default firebaseConfig;