import React, {Component} from 'react'
import * as firebase from "firebase";
import {Button} from "react-bootstrap";
import _ from 'lodash';
import { Table } from 'reactstrap';
import Col from "react-bootstrap/Col";



class ReadFromDB extends Component {
    constructor(props) {
        super(props); //since we are extending class ReadFromDB so we have to use super in order to override Component class constructor
        this.state = { //state is by default an object
            userList: null,
        };

        this.firebaseLogout = this.firebaseLogout.bind(this);
        this.readFromFirebase = this.readFromFirebase.bind(this);
        this.readUserData = this.readUserData.bind(this);
        // this.renderTableData = this.renderTableData.bind(this);
    }


    componentDidMount() {
        this.readUserData();
    }

    readFromFirebase() {

        let userId = firebase.auth().currentUser.uid;
        return firebase.database().ref('persons').once('value').then(function (snapshot) {
            let username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
        });
    }

    readUserData() {
        firebase.database().ref('persons').on('value',  (snapshot) => {


            this.setState({
                userList: snapshot.val()
            });
        });
    }

    firebaseLogout() {
        firebase.auth().signOut().then(function () {
            // Sign-out successful.
        }).catch(function (error) {
            // An error happened.
        });
    }

    renderTableData(userList){
        if (_.isNil(userList)){
            return (
                <tr>
                    <td>
                        No datas found
                    </td>
                </tr>
            )
        }
        const list = Object.keys(userList);

        return list.map((uID, index) =>{
            const user = userList[uID];
            const {name, email, candidatureState, job, prename} = user;
            console.log(user);

            return (
                <tr key={index}>
                    <td>{prename}</td>
                    <td>{name}</td>
                    <td>
                        <a href={`mailto:${email}`}>
                            {email}
                        </a>
                    </td>
                    <td>{candidatureState }</td>
                    <td>{job}</td>
                </tr>
            )
        })
    }

    render() { //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
        return (
            <div>
                <h1>Interessenten</h1>
                <div>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Vorname</th>
                                    <th>Nachname</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Job</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.renderTableData(this.state.userList)
                            }
                            {
                                console.log("X: ", this.renderTableData(this.state.userList))
                            }
                            </tbody>

                        </Table>
                    </Col>


                </div>
                <Button onClick={this.firebaseLogout}>Logout</Button>

            </div>
        )
    }
}

export default ReadFromDB;