import React from 'react';
import it_logo from "./res/it_logo.jpg"
import Col from "reactstrap/es/Col";


export default class IT_logo extends React.Component {
    render() {
        return (
            <a href="http://www.autexis-it.com/">
                <img src={it_logo} alt="IT_LOGO" style={{width: "72%"}}/>
            </a>
        );
    }
}