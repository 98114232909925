import React from 'react';



export default class QR_code extends React.Component {
    render() {
        return (

            <img src="http://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fwww.autexis.com%2F&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L" alt="qr code" width={"100%"} />

        );
    }
}