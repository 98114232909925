import React from 'react';
import control_logo from "./res/control_logo.jpg"
import Col from "reactstrap/es/Col";


export default class Control_logo extends React.Component {
    render() {
        return (
            <a href="http://www.autexis-control.com/">
                <img src={control_logo} alt="IT_LOGO" style={{width: "100%"}}/>
            </a>
        );
    }
}