import React, {Component} from 'react';
import {Button, ButtonGroup} from "reactstrap";
import firebaseConfig from "./config/firebase";
import _ from 'lodash';
import PersonalForm from "./PersonalForm";
import IT_logo from "./IT_logo";
import Control_logo from "./control_logo";
import QR_code from "./qr_code";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "reactstrap/es/Col";
import Badge from "react-bootstrap/Badge";
import Login from "./Login";
import ReadFromDB from "./ReadFromDB";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import {switchCase} from "@babel/types";
import * as firebase from "firebase";


let timeoutConn;
let connectionError;


class MainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alarm: false,
            navInt: 0,
            collapsed: true,
            userLoggedIn: false,
        };
        this.handleDBClick = this.handleDBClick.bind(this);
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.firebaseLoginCheck = this.firebaseLoginCheck.bind(this);
        this.authenthicated = this.authenthicated.bind(this);
    }

    componentDidMount() {
        this.firebaseLoginCheck();
    }

    connectionCheck() {
        let connectedRef = firebaseConfig.database().ref(".info/connected");
        connectedRef.on("value", function (snap) {
            if (snap.val()) {
                if (!_.isNil(timeoutConn)) {
                    clearTimeout(timeoutConn);
                }
                console.log("connected");
                connectionError = false;
            } else {
                timeoutConn = setTimeout(() => {
                    console.log("disconnected");
                    connectionError = true;
                }, 1000);
            }
        });
        // connectedRef.on("value", (v) => this.showError(v));
    }

    handleHomeClick() {
        this.setState({navInt: 0});

    }

    handleDBClick() {
        this.setState({navInt: 1});


    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    authenthicated(valid) {
        this.setState({userLoggedIn: valid});
    }

    firebaseLoginCheck() {
        let valid = false;
        firebase.auth().onAuthStateChanged((user) => {

            if (user) {
                // User is signed in.
                var displayName = user.displayName;
                var email = user.email;
                var emailVerified = user.emailVerified;
                var photoURL = user.photoURL;
                var isAnonymous = user.isAnonymous;
                var uid = user.uid;
                var providerData = user.providerData;
                console.log("logged in");
                // this.setState({userLoggedIn : true});
                valid = true;

            } else {
                // User is signed out.
                console.log("logged out");
                valid = false;
                // this.setState({userLoggedIn : false});

            }
        this.authenthicated(valid);
            // console.log("valid: ", this);
        });
    }

    render() {
        return (
            <div>
                <Row style={{marginBottom: "10px"}}>
                    <Col md={4} lg={4}>
                        <IT_logo/>
                    </Col>
                    <Col md={4} lg={4} style={{textAlign: "center"}}>
                        <h1 style={{fontSize: "60px"}}><Badge color="secondary">Career Day</Badge></h1>
                    </Col>
                    <Col md={4} lg={4}>
                        <Control_logo/>
                    </Col>
                </Row>

                <div style={{marginBottom: "10px"}}>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand onClick={this.handleHomeClick} className="mr-auto">Formular</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                        <Collapse isOpen={!this.state.collapsed} navbar>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <NavLink onClick={this.handleDBClick}>View Database</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>

                {this.state.navInt === 0 ?
                    <Col lg={{size: 10, offset: 1}} xs={{size: 12}}>
                    <PersonalForm/>
                    </Col>
                    :
                    <div/>
                }
                {this.state.navInt === 1 ?
                    this.state.userLoggedIn ?
                        <ReadFromDB/>
                        :
                        <Login/>
                    :
                    <div/>
                }

            </div>
        );
    }
}

export default MainPage;