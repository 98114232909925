import React from 'react';
import {Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import firebaseConfig from "./config/firebase";
import Col from "reactstrap/es/Col";


export default class PersonalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            prename: '',
            job: 'Software Engeneer IT',
            candidatureState: 'bin jetzt auf der Suche',

        };


        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePrenameChange = this.handlePrenameChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleJobChange = this.handleJobChange.bind(this);
        this.handleCharacterChange = this.handleCharacterChange.bind(this);

        this.submitClicked = this.submitClicked.bind(this);

    }

    submitClicked() {
        const { prename, name, email, job, candidatureState } = this.state;
        firebaseConfig.database().ref('persons').push({
            prename,
            name,
            email,
            job,
            candidatureState
        })
        this.setState({
            email: '',
            name: '',
            prename: '',
            job: 'Software Engeneer IT',
            candidatureState: 'bin jetzt auf der Suche',
        })
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handlePrenameChange(event) {
        this.setState({prename: event.target.value});
    }

    handleNameChange(event) {
        this.setState({name: event.target.value});
    }

    handleCharacterChange(event) {
        this.setState({candidatureState: event.target.value});
    }

    handleJobChange(event) {
        this.setState({job: event.target.value});
    }

    render() {
        return (
            <Form>
                <FormGroup>
                    <Label for="exampleSelect">Ich...</Label>
                    <Input
                        type="select"
                        name="selectCharacter"
                        id="exampleSelect"
                        value={this.state.candidatureState}
                        onChange={this.handleCharacterChange}
                    >
                        <option>bin jetzt auf der Suche</option>
                        <option>bin in einem Jahr auf der Suche</option>
                        <option>interessiere mich für die Stelle</option>
                        <option>möchte mich bewerben</option><option>bin jetzt auf der Suche</option>

                    </Input>
                </FormGroup>
                {/*</Col>*/}
                <FormGroup>
                    <Label for="exampleSelect">Stelle</Label>
                    <Input
                        type="select"
                        name="selectJob"
                        id="exampleSelect"
                        value={this.state.job}
                        onChange={this.handleJobChange}
                    >
                        <option>Software Engineer IT</option>
                        <option>Software Engineer Automation</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleUrl">Vorname</Label>
                    <Input
                        type="text"
                        name="prename"
                        id="exampleUrl"
                        placeholder="Vorname"
                        value={this.state.prename}
                        onChange={this.handlePrenameChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="name">Nachname</Label>
                    <Input
                        type="text"
                        name="name"
                        id="exampleUrl"
                        placeholder="Nachname"
                        value={this.state.name}
                        onChange={this.handleNameChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Button onClick={this.submitClicked} value="submit">
                        Submit
                    </Button>
                </FormGroup>

            </Form>
        );
    }
}