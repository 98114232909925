// import 'bootstrap/dist/css/bootstrap.min.css';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainPage from "./MainPage";





ReactDOM.render(
    <MainPage/>,
    document.getElementById('root')
);
